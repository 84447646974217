import React from 'react';


const Terms = () => {

  return (
    <>
      <div className="w-full flex justify-center bg-gradient-to-b via-[#202e44] from-black to-[#596b87]">
        <div className="playcard max-w-screen-lg w-full rounded-[29px] mb-5">
          <div className="w-full text-white p-5">
            <p><strong>TERMS OF USE – LUCKBLOCKS</strong></p>
            <p><strong>1. ACCEPTANCE OF TERMS</strong></p>
            <p>Before using any service or product related to <strong>LUCKBLOCKS</strong>, you must read and agree to all the terms and conditions of use. These terms and conditions govern and apply to the website www.luckblocks.com, hereinafter referred to as &quot;<strong>LUCKBLOCKS</strong>,&quot; and any separate agreements by which we provide Services to you will be governed and interpreted in accordance with the laws of the <strong>MALTA</strong>.</p>
            <p>By registering and clicking &quot;Agree&quot; on the website www.luckblocks.com, <strong>you as a user</strong> (<strong>&quot;You&quot;; &quot;Your&quot;; &quot;User&quot;; &quot;Player&quot;</strong>) agree to these terms of use, and all terms and conditions described below, including the Privacy Policy and other applicable policies or rules (&quot;<strong>Terms</strong>&quot;) for the use of the Site. As such, you are entering into a legally binding agreement with us, and your use and access to our site mean that you have read, understood, and agreed to be bound by our Terms. Be aware that by agreeing, all your rights and obligations will be bound, making these requirements and conditions of use essential for the contracting of any requested services.</p>
            <p><strong>Therefore, before accepting, make sure that you have understood and agreed with the complete content of these terms and conditions of use.</strong> </p>
            <p>Minors under 18 (eighteen) years of age are strictly prohibited from submitting their personal data or third-party data and cannot use or operate any service provided or offered by <strong>LUCKBLOCKS</strong> according to the minimum laws of each country. <strong>LUCKBLOCKS</strong> is not able to verify the legality of the Service in each jurisdiction, and it is the User&#39;s responsibility to ensure that the use of the Service is legal.</p>
            <p><strong>LUCKBLOCKS</strong> and its licensors are the sole owners of all rights to the Service and the code, structure, and organization, including copyrights, trade secrets, intellectual property, and other rights. You may, within the limits of the GNU General Public License (GPL), use the software freely, provided that any changes and their impacts on the project are expressly notified in writing. You agree that all expenses arising from any Unauthorized and/or Illegal Use not agreed upon with the project shall be immediately communicated to <strong>LUCKBLOCKS</strong>, which will provide reasonable assistance in any investigations conducted in light of the information provided.</p>
            <p>The term &quot;<strong>LUCKBLOCKS</strong>,&quot; its domain names, and any other trademarks or service marks used by <strong>LUCKBLOCKS</strong> as part of the Service (the &quot;Trademarks&quot;) are the exclusive property of <strong>LUCKBLOCKS</strong>. Additionally, all content on the site, including but not limited to images, photos, graphics, photographs, animations, videos, music, audio, and text (the &quot;Site Content&quot;), belongs to <strong>LUCKBLOCKS</strong> and is protected by copyright and/or other intellectual property or other rights. You acknowledge that by using the Service, you do not obtain any rights to the Site Content and/or Trademarks, or any part thereof. Under no circumstances may you use the Site Content and/or Trademarks without the prior written consent of <strong>LUCKBLOCKS</strong>. Furthermore, you agree not to do anything that may harm or potentially harm the rights, including intellectual property rights, of <strong>LUCKBLOCKS</strong>.</p>
            <h4 id="-2-definitions-"><strong>2. DEFINITIONS</strong></h4>
            <p>In these &quot;<strong>Terms of Use – LUCKBLOCKS</strong>,&quot; the following expressions shall have the meanings attributed below: </p>
            <p><strong>a) User:</strong> A natural or legal person, of legal age and capacity, duly identified, who will use LUCKBLOCKS services after accepting these terms of use. </p>
            <p><strong>b) Interfaces</strong>: LUCKBLOCKS applications, websites, and service channels. </p>
            <p><strong>c) Our Site</strong>: <a href="http://www.LUCKBLOCKS.com">www.luckblocks.com</a> (<a href="https://LUCKBLOCKS.com/">https://luckblocks.com/</a>). </p>
            <p><strong>d) Cryptoasset</strong>: A digital asset based on cryptography registered on a blockchain network. </p>
            <p><strong>e) Blockchain</strong>: An advanced database that stores data through a consistent chain that does not allow alteration, recording transactions made using cryptoassets. </p>
            <p><strong>f) Cryptoasset Wallet</strong> or <strong>Wallet</strong>: Software or device that stores access information to cryptocurrencies stored on the blockchain. </p>
            <p><strong>g) Private Key</strong>: An alphanumeric key known exclusively to the user that allows the recovery of cryptoasset wallets, being the only means of recovery and should be kept secure, never shared with third parties. </p>
            <p><strong>h) IPFS (InterPlanetary File System)</strong>: A decentralized and distributed network protocol that allows file storage and retrieval in a peer-to-peer manner, using a distributed network of computers instead of relying on a central server. This means that the files on the Interface are not stored in a single location but are spread across several nodes within the IPFS network.</p>
            <h4 id="-3-use-of-the-website-"><strong>3. USE OF THE WEBSITE</strong></h4>
            <p>3.1 The <strong>LUCKBLOCKS</strong> website is provided directly on the site <a href="http://www.luckblocks.com">www.luckblocks.com</a> and may occasionally contain errors, malfunctions, or temporary unavailability, as it uses Blockchain. The User understands and agrees that the use of the website is at their own risk, fully possessing custody of their assets through the private key. The User agrees to provide feedback on the website, including error reports, performance issues, or any other relevant information for its improvement.</p>
            <p>3.2 <strong>LUCKBLOCKS is a decentralized and innovative raffle lottery built on the Polygon blockchain. Participants can win prizes in cryptocurrencies such as USDT, BTC, ETH, MATIC. The platform uses Chainlink VRF 2.0 nodes to ensure truly random and verifiable results.</strong> </p>
            <p><strong>By purchasing a ticket for the daily lottery, users also automatically receive a chance to participate in the weekly lottery. If the minimum number of participants or pot value is not reached, the prize is rolled over to the next cycle. The draws are executed on-chain (i.e., directly via Blockchain) by</strong> </p>
            <p><strong>NFTnodes, with rewards for operators. LUCKBLOCKS is self-sustaining, operating through smart contracts without the need for centralized maintenance, and is open-source, available under the GNU license, with an IPFS version for greater decentralization and censorship resistance. More information about the entire process is available at: <a href="https://docs.luckblocks.com/">https://docs.luckblocks.com/</a></strong></p>
            <h4 id="-4-limitation-of-liability-"><strong>4. LIMITATION OF LIABILITY</strong></h4>
            <p>4.1 The User understands and agrees that the <strong>LUCKBLOCKS</strong> website, provided by official social channels (Instagram: @luckblockscom, Telegram: @luckblockscom, Discord: <a href="https://discord.gg/NAnBqc4g">https://discord.gg/NAnBqc4g</a>, X/Twitter: @luckblockscom), and third parties that provide technology for its operation, will not be responsible for any direct, indirect, incidental, special, consequential, or punitive damages arising from the use or inability to use the application.</p>
            <p>4.2 <strong>LUCKBLOCKS</strong> disclaims all warranties, express or implied, in connection with the service provided to you &quot;as is&quot; and makes no warranties or representations regarding its quality, suitability for a particular purpose, completeness, or accuracy. Regardless of <strong>LUCKBLOCKS&#39;</strong> efforts, there is no guarantee that the service will be uninterrupted, timely, or error-free or that defects will be corrected.</p>
            <p>4.3 Under no circumstances, including negligence, will <strong>LUCKBLOCKS</strong> be liable for any direct, indirect, special, incidental, or consequential damages (including loss of business profits, business interruption, or loss of business information) arising from the use or misuse of the Service, even if <strong>LUCKBLOCKS</strong> was previously aware of the possibility.</p>
            <p>4.4 These Terms of Service do not exclude or limit <strong>LUCKBLOCKS&#39;</strong> liability for death or personal injury caused by its negligence.</p>
            <p>4.5 Users retain full control over their cryptoassets. It is important to understand that neither <strong>LUCKBLOCKS</strong> nor any affiliated entity is a party to any transaction on the underlying blockchain networks of the Polygon Blockchain; there is no possession, custody, or control over any cryptoassets or user funds. You understand that when interacting with the Polygon Blockchain, you maintain control over your cryptoassets at all times.</p>
            <p>4.6 Users use third-party self-custody wallets to interact with the Polygon Protocol; <strong>LUCKBLOCKS</strong> has no control or guarantee over these wallets.</p>
            <p>4.7 To interact with the Polygon Blockchain using the Interface, it is necessary to connect through your self-custody wallet. It is essential to understand that your self-custody wallet is provided by a third-party entity and is generally governed by separate terms and conditions established by the respective third-party service provider.</p>
            <p>4.8 These terms and conditions may involve additional fees, disclaimers, or warnings about the accuracy and reliability of the information provided.</p>
            <p>4.9 It is your sole responsibility to review and understand the terms and conditions associated with your chosen self-custody wallet provider to ensure compliance and be aware of any applicable fees or risks.</p>
            <p>4.10 <strong>LUCKBLOCKS</strong> is not an intermediary in transactions on the Polygon Blockchain.</p>
            <p>4.11 Due to the non-custodial and decentralized nature of the technology, <strong>LUCKBLOCKS</strong> is not an intermediary, agent, consultant, or custodian, and there is no fiduciary relationship or obligation to you regarding any other decisions or activities you engage in while using our Services.</p>
            <p>4.12 You acknowledge that <strong>LUCKBLOCKS</strong> has information about all transactions on the Polygon Blockchain beyond what is available or can be obtained publicly via the blockchain. However, we may collect information about users of the Services in accordance with our Privacy Policy. There may be fees associated with the blockchain. <strong>LUCKBLOCKS</strong> does not possess sensitive information about all transactions on the Protocol, but the blockchain is public and immutable, so any data provided beyond may be available publicly.</p>
            <p>4.13 Transactions using blockchains may require the payment of GAS fees, which are essentially network transaction fees paid for each transaction that occurs on the selected blockchain network. Please note that GAS fees are non-refundable. <strong>LUCKBLOCKS</strong> does not provide any services or products to users, nor do they deliver, maintain, or receive payment for cryptoassets. <strong>LUCKBLOCKS</strong> only allows the purchase of lottery tickets with their fees duly included and communicated at the time of purchase, also available for consultation at: <a href="https://docs.luckblocks.com">https://docs.luckblocks.com</a>.</p>
            <p>4.14 The Interface is hosted on the InterPlanetary File System (&quot;IPFS&quot;), a decentralized and distributed network protocol that allows the storage and retrieval of files in a peer-to-peer manner, using a distributed network of computers instead of relying on a central server. This means that the files on the Interface are not stored in a single location but are spread across several nodes within the IPFS network, and as such, you understand that the Interface is hosted on a decentralized infrastructure for storing and accessing data and content.</p>
            <p>4.15 You agree with the game rules described on the <strong>LUCKBLOCKS</strong> site, which retains authority over the issuance, maintenance, and termination of the Service. The decision of the <strong>LUCKBLOCKS</strong> administration regarding any use of the Service or dispute resolution is final and not subject to review or appeal.</p>
            <h4 id="-5-fees-and-limits-"><strong>5. FEES AND LIMITS</strong></h4>
            <p>5.1 <strong>LUCKBLOCKS</strong> does not charge monthly fees for providing services to the user, with fees charged only for each ticket purchase transaction.</p>
            <p>5.2 All fee amounts charged by <strong>LUCKBLOCKS</strong> are integrated and duly communicated in the purchase form, along with the minimum values and transaction limits, which will be available on the <strong>LUCKBLOCKS</strong> website, and payments are exclusively in crypto assets.</p>
            <p>5.3 <strong>LUCKBLOCKS</strong> reserves the right to make changes to its products, services, or those of third parties, or to the applicable prices thereof, at any time, unilaterally and without prior notice for such changes.</p>
            <p>5.4 Additional terms and fees may apply to access or use specific functionalities of <strong>LUCKBLOCKS</strong> Services.</p>
            <h4 id="-6-about-luckblocks-services-and-policies-"><strong>6. ABOUT LUCKBLOCKS SERVICES AND POLICIES</strong></h4>
            <p>6.1 It is the USER&#39;s duty to follow the policies, terms, and instructions made available by <strong>LUCKBLOCKS</strong>.</p>
            <p>6.2 You participate in the Games strictly in your personal and non-professional capacity, solely for recreational and entertainment purposes.</p>
            <p>6.3 You acknowledge that games are for fun, but a small percentage of individuals may lose control while playing. It is essential to understand that gambling should not be viewed as a source of income or a way to pay off debts. It is advisable to monitor the amount of time and money spent daily on an online lottery.</p>
            <p>6.4 You understand that participating in the Games risks losing virtual funds deposited in your preferred wallet.</p>
            <p>6.5 All information provided to <strong>LUCKYBLOCKS</strong> during the validity of this contract is true, complete, and correct, and you must immediately notify <strong>LUCKBLOCKS</strong> of any changes.</p>
            <p>6.6 You are solely responsible for reporting and accounting for any applicable taxes related to any winnings received from <strong>LUCKBLOCKS</strong>.</p>
            <p>6.7 You understand that Virtual Funds such as Bitcoin are not considered currency or legal tender and, as such, are treated on the Site as virtual funds with no intrinsic value. You understand that the value of Bitcoin can change dramatically depending on market value.</p>
            <p>6.8 Certain circumstances may arise when a purchase is confirmed or payment is made in error. In all such cases, <strong>LUCKBLOCKS</strong> does not reserve the right to cancel or refund, as all transactions are directly on the blockchain, making any errors irreversible for a refund.</p>
            <p>6.9 There may be an automated refund or return mechanism if the user cancels the ticket purchase, resulting in the loss of 75% of the value of the ticket purchase order.</p>
            <p>6.10 If you become aware of any potential errors or omissions on the website, you must refrain from exploiting them. Additionally, you agree to report any error or omission immediately to <strong>LUCKBLOCKS</strong>. If you fail to fulfill the obligations stated in this clause, <strong>LUCKBLOCKS</strong> has the right to full compensation for all costs related to the error or omission, including any costs incurred in connection with the respective error/omission and the failure to notify directly through the official support channel.</p>
            <p>6.11 <strong>LUCKBLOCKS</strong> prohibits the dissemination of any content within its social media, blogs, and service channels that is defamatory, offensive, untruthful, racist, sexist, homophobic, pornographic, violent, political, drug-promoting, terrorism-promoting, money laundering-promoting, corruption-promoting, or otherwise violates laws, harms the image of <strong>LUCKBLOCKS</strong>, third parties, or conflicts with morality and good customs.</p>
            <p>6.12 The proper functioning of <strong>LUCKBLOCKS</strong> services and products may depend on the compatibility and proper functioning of third-party products and services, such as smartphones, tablets, personal computers, blockchain, and telecommunications services.</p>
            <p>6.13 In the event of a violation of one or more provisions of these Terms of Service, <strong>LUCKBLOCKS</strong> reserves the right to take all appropriate measures, including terminating the Contract and filing legal action, in addition to immediate suspension.</p>
            <p>6.14 You agree to indemnify, defend, and hold <strong>LUCKBLOCKS</strong> and its shareholders, directors, agents, and employees harmless from all claims, demands, liabilities, damages, losses, costs, and expenses, including attorney&#39;s fees and any other charges, regardless of cause, that may arise as a result of: (i) violation of these Terms of Service; (ii) violation of any law or third-party rights; and (iii) use of the Service.</p>
            <h4 id="-7-website-luckblocks-general-rules-"><strong>7. WEBSITE LUCKBLOCKS – GENERAL RULES</strong></h4>
            <p>7.1 To fully utilize the functionalities of the <strong>LUCKBLOCKS</strong> website, the user must have a unique wallet address for integration through Wallet Connect.</p>
            <p>7.2 The user acknowledges that <strong>LUCKBLOCKS</strong> does not hold custody of assets of any nature, and the <strong>LUCKBLOCKS</strong> website serves only as an interface for accessing the user&#39;s cryptoasset address and as an integrator between the blockchain.</p>
            <p>7.3 The user acknowledges that, due to the security features of Blockchain technologies employed on the website and the generated address, the recovery of cryptoasset balances, private keys, mnemonics, and security words is not possible, as no records of any nature exist with <strong>LUCKBLOCKS</strong> or third parties involved in the platform&#39;s operation.</p>
            <p>7.4 During the ticket purchase process, currency price volatility may occur, without any compensation or value replacement from <strong>LUCKBLOCKS</strong>.</p>
            <p>7.5 <strong>THE CRYPTOASSETS THAT THE USER MAY ACQUIRE USING THE LUCKBLOCKS WEBSITE DO NOT GENERATE MONTHLY RETURNS, DIVIDENDS, OR REMUNERATION OF ANY KIND, BEING OF LAWFUL ORIGIN, AND THEIR VALUE IS SOLELY LINKED TO THE BLOCKCHAIN TO WHICH THEY BELONG.</strong></p>
            <h4 id="-8-game-rules-lottery-"><strong>8. GAME RULES - LOTTERY:</strong></h4>
            <p>8.1 <strong>LUCKBLOCKS</strong> allows the purchase of a lottery raffle ticket with a daily chance and an additional chance in the weekly mode, as distributed below: Each ticket you purchase is distributed as follows:</p>
            <ul>
              <li>47.5% goes to the daily lottery  </li>
              <li>47.5% goes to the weekly lottery  </li>
              <li>2% goes to the CRANNI-WPOL LP Stakers Pool  </li>
              <li>2% goes to the executor NFTnodes  </li>
              <li>1% goes to the platform</li>
            </ul>
            <p>8.2 There are four options in lotteries/raffles with varying prices and difficulty levels:</p>
            <ul>
              <li>BTC: price of $1, minimum pot value for draw of $20, and 20 contract tickets.  </li>
              <li>ETH: price of $5, minimum pot value for withdrawal of $50, and 10 contract tickets.  </li>
              <li>MATIC: price of $10, minimum pot value for draw of $100, and 5 contract tickets.  </li>
              <li><strong>CRANNI:</strong> price of $15, minimum pot value for draw of $200, and 2 contract tickets.</li>
            </ul>
            <p>8.3 If the <strong>LUCKBLOCKS</strong> contract wins the draw, the jackpot accumulates and is distributed internally as follows:</p>
            <ul>
              <li>25% goes to storage for future draws, minimum prize fulfillment, and minimum payment for NFTnodes  </li>
              <li>60% goes to the next draw prize  </li>
              <li>15% goes to the weekly lottery prize.</li>
            </ul>
            <p>8.4 Before the draw, that is, where transactions have not yet been completed, tickets can be canceled, with the automatic refund rule of 75% of the ticket purchase order value. More information is available at: <a href="https://docs.luckblocks.com/">https://docs.luckblocks.com/</a></p>
            <h4 id="-9-bonus-and-incentive-policy-"><strong>9. BONUS AND INCENTIVE POLICY</strong></h4>
            <p>9.1 NFTnodes, also known as Cranni Societas, is a community of peers/nodes that plays a crucial role in <strong>LUCKBLOCKS&#39;</strong> operation. As holders of the NFT Collection, they are responsible for executing each draw on the chain. To provide this service, Cranni Societas holders must stake their NFTs in our smart contract and configure the automation as they prefer, such as VPS servers or Gelato, to be part of the payment queue.</p>
            <p>9.2 By participating in this service, the executing node for that draw will receive a reward of 2% of each ticket sold if they draw the daily lotteries with Common NFTnodes and a reward of 9.50% of the weekly lottery jackpot if they draw the weekly lotteries with Golden NFTnodes.</p>
            <p>9.3 This model is a community incentive approach and also ensures secure operation on the website without the need for developer maintenance or centralized support.</p>
            <p>9.4 NFTnodes is an integral part of the <strong>LUCKBLOCKS</strong> ecosystem, providing decentralized and reliable support for the platform&#39;s lottery draws.</p>
            <p>9.5 Incentives for CRANNI token holders. As a CRANNI token holder, you can benefit from the success of <strong>LUCKBLOCKS</strong> by holding CRANNI-WPOL LP and staking in the pool dapp at luckblocks.com. You will receive a portion of the monthly revenue generated from ticket sales. Holders of 888 or more CRANNI tokens will receive a 20% discount on all lottery tickets, providing more incentive to trade and use the <strong>LUCKBLOCKS</strong> website. They can also control changes such as the number of CRANNI tokens required for discount bonuses and other changes within the <strong>LUCKBLOCKS</strong> website.</p>
            <p>9.6 Decentralized pool with StableCoin rewards. <strong>LUCKBLOCKS</strong> fills the Liquidity Mining Pool, a decentralized liquidity management of the C.R.A.N.N.I Ecosystem that rewards users with stablecoin for their participation. The pool is supported by a 2% fee on each ticket purchased, so users can participate in <strong>LUCKBLOCKS&#39;</strong> revenue by providing liquidity to CRANNI-WPOL LP on Sushiswap DEX and staking their LP token in the pool. Rewards are dynamic and depend on the lottery revenue within a month. More information can be found at: <a href="https://docs.luckblocks.com/">https://docs.luckblocks.com/</a> - Staking 5% discount on tickets. Buying 10 or more tickets in the same transaction will give you a 5% discount on the total cost, with a total discount of 25% if you have 88 CRANNI, which means you can get even better returns on raffle prizes.</p>
            <p><strong>10. INTELLECTUAL PROPERTY</strong></p>
            <p>10.1 The user acknowledges that <strong>LUCKBLOCKS</strong> owns all intellectual property employed on the <strong>LUCKBLOCKS</strong> website, Products, and Services, or in any material created or made available by <strong>LUCKBLOCKS</strong>. Such intellectual property includes, but is not limited to: (a) trademarks, corporate names, service names, slogans, trade dress, logos, internet domain names, and other distinctive signs, as well as all related applications, registrations, extensions, and renewals; (b) patents, patent applications, and all related renewals, utility models, utility model applications, additional certificates, additional certificate applications, related extensions and renewals, and invention records; (c) industrial design registrations and industrial design registration applications, related extensions and renewals; (d) mappings; (e) circuit topologies; (f) copyrights, computer programs, layouts, presentation forms, color combinations, source codes, and related registrations and applications; (g) trade secrets and know-how.</p>
            <p>10.2 The user agrees not to infringe, reproduce, imitate, in whole or in part, any intellectual property of <strong>LUCKBLOCKS</strong>, nor to use any intellectual property of <strong>LUCKBLOCKS</strong> for any purposes other than those provided in these terms and permitted by law.</p>
            <p>10.3 The licenses offered by <strong>LUCKBLOCKS</strong> are solely intended to allow the user to use and enjoy the benefits of the services provided, in the manner permitted by these terms, and must be respected as such, in a way that any changes are tacitly informed.</p>
            <p><strong>11. PRIVACY</strong></p>
            <p>11.1 <strong>LUCKBLOCKS&#39;</strong> Privacy Policy explains how your personal data will be treated and your privacy protected when you use our Services. By using our Services, you agree that <strong>LUCKBLOCKS</strong> may use such data in accordance with our Privacy Policy.</p>
            <p>11.2 <strong>LUCKBLOCKS</strong> may share your data with third parties for processing, storage, and analysis purposes, strictly to enable transactions using the <strong>LUCKBLOCKS</strong> website.</p>
            <p>11.3 The Interface used by <strong>LUCKBLOCKS</strong> is hosted on the InterPlanetary File System (&quot;IPFS&quot;), a decentralized and distributed network protocol that allows the storage and retrieval of files in a peer-to-peer manner, using a distributed network of computers instead of relying on a central server. This means that the files on the Interface are not stored in a single location but are spread across several nodes within the IPFS network.</p>
            <p>11.4 You understand that the Interface is hosted on a decentralized infrastructure for storing and accessing data and content, the IPFS.</p>
            <p><strong>12. MODIFICATIONS TO THE LUCKBLOCKS WEBSITE AND TERMS OF USE</strong></p>
            <p>12.1 <strong>LUCKBLOCKS</strong> may modify these terms or any additional terms and policies that apply to the service to reflect changes in the law or service changes unilaterally. The user is responsible for consulting the terms and policies. When applicable, we will publish a change notice about the additional terms within our official channels. Changes will take effect within the timeframe indicated in their publication. Changes regarding new Service features or changes made for legal reasons will take effect immediately.</p>
            <p>12.2 <strong>LUCKBLOCKS</strong> may also expand the use of the <strong>LUCKBLOCKS</strong> website by adding new services and products or discontinuing the provision of certain products or services at any time.</p>
            <p><strong>13. GENERAL PROVISIONS</strong></p>
            <p>13.1 The user declares that they are responsible, civilly and criminally, for all operations performed on the <strong>LUCKBLOCKS</strong> website, also declaring that the money used in transactions through the website has a legal origin. The use of the website for crimes such as money laundering and currency evasion, among others, is strictly prohibited, and the user may be held civilly and criminally liable not only to the authorities, without prejudice to any losses and damages caused to <strong>LUCKBLOCKS</strong>.</p>
            <p>13.2 The user declares sufficient knowledge to operate the services offered by <strong>LUCKBLOCKS</strong>, with no doubt or fact that could characterize their vulnerability concerning the same, having full and total civil capacity and being fit to perform any act necessary for the validity of the operations.</p>
            <p>13.3 <strong>LUCKBLOCKS</strong> is not responsible for any direct or indirect loss of profits, data loss, or damages resulting from technical errors, failures, interrupted internet connection, or the like.</p>
            <p>13.4 In the event of a conflict between these terms and additional terms, the additional terms will prevail concerning that conflict.</p>
            <p>13.5 These terms govern the relationship between <strong>LUCKBLOCKS</strong> and the user, not creating any rights for third parties.</p>
            <p>13.6 The conditions agreed upon herein will be reviewed whenever events arising from changes in tax, regulatory, economic, or any other relevant legislation substantially alter the obligations of the user or <strong>LUCKBLOCKS</strong> defined in these terms.</p>
            <p>13.7 The jurisdiction of the <strong>MALTA</strong> is elected to resolve any doubts or disputes arising from this term.</p>
            <p>For information on how to contact <strong>LUCKBLOCKS</strong>, visit the website www.luckblocks.com or contact the Service Channels.</p>
            <pre><code>   <span class="hljs-strong">***Last update: July 31, 2024\.**</span>*
            </code></pre>
          </div>
        </div>
      </div>
    </>
  );
};

export default Terms;
