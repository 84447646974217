import React, { useState, Suspense, lazy } from 'react';
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Hero from './Hero';
import Main from './Main';
import Bitcoin from './pages/Bitcoin';
import Ethereum from './pages/Ethereum';
import CRANNI from './pages/CRANNI';
import Matic from './pages/Matic';
import MyTickets from './pages/MyTickets';
import {
  luckBlocksAddress,
  ethLuckBlocksAddress,
  maticLuckBlocksAddress,
  CRANNILuckBlocksAddress,
} from './.config';
import {
  EthereumClient,
  w3mConnectors,
  w3mProvider,
} from '@web3modal/ethereum';
import { Web3Modal } from '@web3modal/react';
import { configureChains, createConfig, WagmiConfig } from 'wagmi';
import { alchemyProvider } from 'wagmi/providers/alchemy';
import { jsonRpcProvider } from 'wagmi/providers/jsonRpc';
import { polygonMumbai, polygon, bscTestnet } from 'wagmi/chains';
import { useAccount } from 'wagmi';
import HeroNFT from './HeroNFT';
import NFTnodes from './pages/NFTnodes';
import HeroToken from './HeroToken';
import Token from './pages/Token';
import Terms from "./pages/Terms";

// const Main = lazy(() => import("./Main"));
const Partners = lazy(() => import('./Partners'));
const Footer = lazy(() => import('./components/Footer'));
const Social = lazy(() => import('./Social'));
const ChooseNumber = lazy(() => import('./components/UI/Modals/ChooseNumber'));
const Success = lazy(() => import('./components/UI/Modals/Success'));
// const NFTnodes = lazy(() => import("./pages/NFTnodes"));

const chains = [bscTestnet];
const projectId = 'd5cbf7e239d6f43a9228147366120ae5';

/*
const { publicClient } = configureChains(chains, [
  alchemyProvider({ apiKey: 'rH_6eF58MmxmyBy3FGdKCTYvUSnBizxH' }),
]); */


const { publicClient } = configureChains(chains, [
  jsonRpcProvider({
    rpc: (chain) => {
      if (chain.id !== bscTestnet.id) return null;
      return { http: 'https://data-seed-prebsc-1-s1.binance.org:8545' };
    },
  }),
]);

const wagmiConfig = createConfig({
  autoConnect: true,
  connectors: w3mConnectors({ projectId, version: 2, chains }),
  publicClient,
});



const ethereumClient = new EthereumClient(wagmiConfig, chains);

// const hello = process.env.REACT_APP_TEST_VARIABLE;

// const w3Provider = wagmiConfig.connectors[0];

function App() {
  const [showNumber, setShowNumber] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  const { address, isConnected } = useAccount();

  function getCurrentLAddress() {
    // Logic to determine the laddress based on the current page
    const { hash } = window.location;
    switch (hash) {
      case '#/ethereum':
        return ethLuckBlocksAddress;
      case '#/matic':
        return maticLuckBlocksAddress;
      case '#/CRANNI':
        return CRANNILuckBlocksAddress;
      default:
        return luckBlocksAddress;
    }
  }

  return (
    <>
      <WagmiConfig config={wagmiConfig}>
        <>
          <Router>
            <Header />

            <Routes>
              <Route
                path="/"
                element={
                  <>
                    <Hero
                      setShowDraw={() => {
                        setShowNumber(true);
                      }}
                    />
                    <Main />
                    <Bitcoin
                      setShowDraw={() => {
                        setShowNumber(true);
                      }}
                    />
                  </>
                }
              />
              <Route
                path="/ethereum"
                element={
                  <>
                    <Hero
                      setShowDraw={() => {
                        setShowNumber(true);
                      }}
                    />
                    <Main />
                    <Ethereum
                      setShowDraw={() => {
                        setShowNumber(true);
                      }}
                    />
                  </>
                }
              />
              <Route
                path="/CRANNI"
                element={
                  <>
                    <Hero
                      setShowDraw={() => {
                        setShowNumber(true);
                      }}
                    />
                    <Main />
                    <CRANNI
                      setShowDraw={() => {
                        setShowNumber(true);
                      }}
                    />
                  </>
                }
              />
              <Route
                path="/matic"
                element={
                  <>
                    <Hero
                      setShowDraw={() => {
                        setShowNumber(true);
                      }}
                    />
                    <Main />
                    <Matic
                      setShowDraw={() => {
                        setShowNumber(true);
                      }}
                    />
                  </>
                }
              />
              <Route
                path="/my-tickets"
                element={
                  <>
                    <Hero
                      setShowDraw={() => {
                        setShowNumber(true);
                      }}
                    />
                    <Main />
                    <MyTickets />
                  </>
                }
              />

              <Route
                path="/token"
                element={
                  <>
                    <HeroToken />
                    <Token />
                  </>
                }
              />

              <Route
                path="/nftnodes"
                element={
                  <>
                    <HeroNFT />
                    <NFTnodes />
                  </>
                }
              />
              <Route path="/terms" element={<Terms />} />
            </Routes>

            {showNumber && (
              <Suspense>
                <ChooseNumber
                  showNumber={showNumber}
                  setShowNumber={setShowNumber}
                  setShowSuccess={setShowSuccess}
                  isConnected={isConnected}
                  uaddress={address}
                  laddress={getCurrentLAddress()} // Get the appropriate laddress based on the page
                />
              </Suspense>
            )}
            {showSuccess && (
              <Suspense>
                <Success
                  showSuccess={showSuccess}
                  setShowSuccess={setShowSuccess}
                />
              </Suspense>
            )}
            <Suspense>
              <Partners />
              <Footer />
              <Social />
            </Suspense>
          </Router>
          {/* <CarouselPage /> */}
          {/* <div className=" bg-no-repeat bg-cover bg-people-group">
					<Banner />

					<Logs />
				</div> */}
        </>
      </WagmiConfig>

      <Web3Modal
        projectId={projectId}
        ethereumClient={ethereumClient}
        defaultChain={bscTestnet}
        themeVariables={{
          '--w3m-logo-image-url': 'https://i.imgur.com/FidklRx.png',
          '--w3m-background-color': '#000000',
          '--w3m-accent-color': '#9029d9',
          // ...
        }}
        tokenContracts={{
          97: '0x2a3E6a9824c1f59AEFDe1f5eC4F2b4c3460C6992',
          137: '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
        }}
        tokenImages={{
          USDT: 'https://i.imgur.com/2CBfKCE.png',
          DUSD: 'https://i.imgur.com/2CBfKCE.png',
        }}
      />
    </>
  );
}

export default App;
