import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import luckblock from '../../assets/images/luckblocks.png';
import hamburger from '../../assets/utility/hamburger.png';
import close from '../../assets/utility/close.png';
import twitter from '../../assets/socialIcons/twitter.png';
import telegram from '../../assets/socialIcons/telegram.png';

import { Web3Button } from '@web3modal/react';

function FetchConnectionButton() {
  return <Web3Button />;
}

const Header = () => {
  const [isMobileMenu, setMobileMenu] = useState(false);

  const handleMobileMenu = () => {
    setMobileMenu((prevState) => !prevState);
  };

  return (
    <header className="w-full flex bg-[#000000] text-white font-bold justify-center sticky top-0 z-10">
      <div className="max-w-screen-2xl w-full flex justify-between items-center p-3">
        <h1>
          <Link to="/">
            <img className="w-56 md:w-[300px]" src={luckblock} alt="logo" />
          </Link>
        </h1>
        {/* {normal nav} */}
        <div className="hidden lg:flex sm:gap-2">
          <ul className="flex">
            <li className="flex font-tcbnormal justify-center items-center w-28 border-gray-800 border-r hover:border-purple-200">
              <Link to="/">Home</Link>
            </li>
            <li className="flex font-tcbnormal justify-center items-center w-28 border-gray-800 border-r hover:border-purple-200">
              <Link to="/token">Token</Link>
            </li>
            <li className="flex font-tcbnormal justify-center items-center w-28 border-gray-800 border-r hover:border-purple-200">
              <Link to="/nftnodes">NFTnodes</Link>
            </li>
            <li className="flex justify-center font-tcbnormal items-center w-28 border-gray-800 border-r hover:border-purple-200">
              <a
                href="https://docs.luckblocks.com"
                target="_blank"
                rel="noreferrer"
              >
                Docs
              </a>
            </li>
          </ul>
          <>
            <FetchConnectionButton />
          </>
        </div>

        {/* {mobile nav} */}
        <button onClick={handleMobileMenu} className="flex lg:hidden">
          <img src={hamburger} alt="menu" className=" w-6 md:w-9" />
        </button>

        <div
          className={
            isMobileMenu
              ? 'fixed right-0 top-0 w-full h-screen bg-[#000000e8] p-5 ease-in duration-500'
              : 'fixed right-[-130%] top-0 p-5 ease-in duration-500'
          }
        >
          <div className="w-full flex justify-between items-center gap-3">
            <h2>
              <img className="w-[260px] " src={luckblock} alt="logo" />
            </h2>
            <button onClick={handleMobileMenu} className="flex lg:hidden">
              <img src={close} alt="menu" className="w-5" />
            </button>
          </div>
          <div className="w-full mt-16 flex justify-center">
            <ul className="flex flex-col gap-7 mb-3 text-2xl w-full justify-center p-2 items-center">
              <Link to="/" onClick={handleMobileMenu}>
                PLAY NOW!
              </Link>

              <Link to="/token" onClick={handleMobileMenu} >Token</Link>

              <Link to="/nftnodes" onClick={handleMobileMenu}>
                NFTnodes
              </Link>
              <a
                href="https://docs.luckblocks.com"
                target="_blank"
                rel="noreferrer"
                onClick={handleMobileMenu}
              >
                <li>Docs</li>
              </a>
            </ul>
          </div>

          <div className="w-full flex justify-center px-3 py-5">
            <>
              <FetchConnectionButton />
            </>
          </div>
          <div className="flex justify-center gap-8 p-3">
            <a
              href="https://t.me/luckblockscom"
              target="_blank"
              rel="noreferrer"
              onClick={handleMobileMenu}
            >
              <img src={telegram} alt="telegram" className="h-10" />
            </a>
            <a
              href="https://twitter.com/luckblockscom"
              target="_blank"
              rel="noreferrer"
              onClick={handleMobileMenu}
            >
              <img src={twitter} alt="twitter" className="h-10" />
            </a>
          </div>
        </div>
      </div>
    </header>
    // <div className="flex justify-end h-14 w-full p-3 text-black bg-gray-400">
    //   <button
    //     onClick={connectWallet}
    //     className="p-2 flex justify-center items-center bg-blue-400"
    //   >
    //     {userAddress !== null ? shortUserAddress : "Connect Wallet"}
    //   </button>
    // </div>
  );
};
export default Header;
