import React, { useState, useEffect } from 'react';
import loadinggif from './assets/utility/loading.gif';
import tokenBanner from './assets/images/tokenBanner.webp';

const HeroToken = () => {
  const [currentImage, setCurrentImage] = useState(tokenBanner);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const img = new Image();
    img.src = tokenBanner;
    img.onload = () => setLoading(false);
  }, []);

  const backgroundImageStyle = {
    backgroundImage: `url(${currentImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    maxHeight: `620px`,
  };

  return (
    <div className='bg-black'>
      <div
        className={`w-full md:min-h-[620px] flex flex-col md:flex-row items-center justify-center bg-cover bg-center bg-no-repeat relative`}
        style={backgroundImageStyle}
      >
        <div className="absolute inset-0 bg-black bg-opacity-50"></div>

        <div className="relative w-full md:w-1/2 p-6 md:p-12">
          <div className="text-white text-center md:text-left">
            <p className="leading-relaxed mb-4 bg-black/80 p-4 rounded-xl border border-purple-500/30 w-full justify-content-center font-tcbbold text-lg lg:text-2xl mt-10 mb-7 text-center">
              <h2 className="text-4xl font-bold mb-6 text-transparent bg-clip-text bg-gradient-to-r from-purple-300 to-pink-300 drop-shadow-[0_2px_4px_rgba(0,0,0,0.5)]">
                C.R.A.N.N.I: Crypto Revenue and Node Network Income
              </h2>
              
              CRANNI is a multifunctional utility token designed to revolutionize the blockchain ecosystem through advanced economic mechanisms and diverse applications.
            </p>
            {/*
            <div className="bg-purple-900/30 backdrop-blur-sm border border-purple-500/30 rounded-xl p-4 mt-4">
              <p className="text-white/90">
                Unlock the future of decentralized finance with a token that goes beyond traditional cryptocurrency utilities.
              </p>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroToken;